import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import LandingPage from './pages/LandingPage/LandingPage';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer style={{fontSize: "1.5rem"}}
        position= "bottom-right"
        autoClose={4000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
      />
      <Switch>
          <Route path="/" exact component={LandingPage} />
      </Switch>
  </BrowserRouter>
  );
}

export default App;
