import { useHistory } from "react-router-dom";
import { useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';

export function useRequest() {
    const [result, setResult] = useState<any>();
    const [accessDenied, setAccessDenied] = useState<boolean>(false);
    const token = localStorage.getItem('token') || undefined;
    let history = useHistory();

    const handleValidateStatus = (res: any)=>{
        console.log(res)
        if(res !== undefined){
            if(res.status === 401){
                setAccessDenied(true)
                toast.error("Você não tem as permissões necessárias para utilizar essa função.", {autoClose: 5000});
                history.push("/login");
                return false
            }
            if(res.status === 400){
                let message = 'Ocorreu um Erro...';
                if(res.data.hasOwnProperty("err")){
                    message = res.data.err.message
                }else{
                    message = res.data.msg || res.data.message
                }
                toast.error(message);
                return false
            }
            if(res.status === 404){
                const message = res.data.msg || res.data.message || "URL não encontrada!"
                toast.error(message);
                return false
            }
            if(res.status <= 204){
                if(res.config.method === 'delete'){
                    toast.success("Item deletado com sucesso!");
                }
                return true
            }
        }
    }

    const executeGet = async(url: string, data: object, origin: string) =>{
        try{
            if(data){
                const res = await axios.get(`${url}`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                    params: data,   
                    validateStatus: function (status) {
                        return status < 500;
                    }
                })
                if(handleValidateStatus(res)){
                    setResult({'data': res.data, 'origin': origin});
                }else{
                    setResult({'data': undefined, 'origin': origin});
                }
            }else{
                const res = await axios.get(`${url}`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                    validateStatus: function (status) {
                        return status < 500;
                    }
                })
                if(handleValidateStatus(res)){
                    setResult({'data': res.data, 'origin': origin});
                }else{
                    setResult({'data': undefined, 'origin': origin});
                }
                
            }

        }catch(err){
            toast.error("Ocorreu um erro");
        }
    }

    const executePost = async(url: string, data: object, origin: string) =>{
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        try{
            const res = await axios.post(`${url}`, data, {
                headers: { 'Authorization': `Bearer ${token}` },
                validateStatus: function (status) {
                    return status < 500;
                },
                timeout: 10000,
                cancelToken: source.token
            })
            if(handleValidateStatus(res)){
                setResult({'data': res.data, 'origin': origin});
            }else{
                setResult({'data': undefined, 'origin': origin});
            }

        }catch(err: any){
            if(err.code === "ECONNABORTED"){
                source.cancel('Operation canceled by the user.');
                setResult({'data': undefined, 'origin': origin, error: true});
            }else{
                toast.error("Ocorreu um erro");
            }
        }
    }

    const executePut = async(url: string, data: object, origin: string, params: object) =>{
        if(params){
            try{
                const res = await axios.put(`${url}`, data, {
                    headers: { 'Authorization': `Bearer ${token}` },
                    params: params,
                    validateStatus: function (status) {
                        return status < 500;
                    }
                })
                if(handleValidateStatus(res)){
                    setResult({'data': res.data, 'origin': origin});
                }else{
                    setResult({'data': undefined, 'origin': origin});
                }
            }catch(err){
                toast.error("Ocorreu um erro");
            }
        }else{
            try{
                const res = await axios.put(`${url}`, data, {
                    headers: { 'Authorization': `Bearer ${token}` },
                    validateStatus: function (status) {
                        return status < 500;
                    }
                })
                if(handleValidateStatus(res)){
                    setResult({'data': res.data, 'origin': origin});
                }else{
                    setResult({'data': undefined, 'origin': origin});
                }
            }catch(err){
                toast.error("Ocorreu um erro");
            }
        }
    }
    const executeDelete = async(url: string, deleteId: any, origin: string) =>{
        try{
            const res = await axios.delete(`${url}${deleteId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
                validateStatus: function (status) {
                    return status < 500;
                }
            })
            if(handleValidateStatus(res)){
                setResult({'data': res.data, 'origin': origin});
            }else{
                setResult({'data': undefined, 'origin': origin});
            }
        }catch(err){
            toast.error("Ocorreu um erro");
        }
    }

    return [executeGet, executePost, executePut, executeDelete, result, accessDenied];
}
