import './style.scss';

function LoadingDots(props: any) {

  return (
        <div className="loading-dots">
            <div className="dot dot-1" style={{height: props.size || "1rem", width: props.size || "1rem", backgroundColor: props.color}}></div>
            <div className="dot dot-2" style={{height: props.size || "1rem", width: props.size || "1rem", backgroundColor: props.color}}></div>
            <div className="dot dot-3" style={{height: props.size || "1rem", width: props.size || "1rem", backgroundColor: props.color}}></div>
        </div>
    );
}

export default LoadingDots;
