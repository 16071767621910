import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import error_message from '../../assets/images/error_message.svg';
import success_message from '../../assets/images/success_message.svg';
import loading_image from '../../assets/images/loading.svg';
import { useRequest } from "../../hooks/useRequest";
import LoadingDots from "../../components/LoadingDots/LoadingDots";
import './style.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function LandingPage(props: any) {
    const [isAuthenticated, setIsAuthenticated] = useState<any>(true);
    const [isLoading, setIsLoading] = useState<any>(true);
    const [executeGet, executePost, executePut, executeDelete, result, accessDenied] = useRequest();
    let query = useQuery();
	let code = query.get('code')
    useEffect(() => {
        executePost("https://cloud.melibeat.com.br:3008/api/sellers/register", {"code": code}, "postData")
    }, [code])

    useEffect(() => {
        if(result !== undefined){
            if(result.origin === "postData"){
                if(result.data){
                    setIsAuthenticated(true);
                    setIsLoading(false);
                }else{
                    if(result.error){
                        executePost("https://cloud.melibeat.com.br:3008/api/sellers/register", {"code": code}, "postData")
                    }
                    setIsAuthenticated(false);
                }
            }
            else{
                return
            }
        }
    }, [result])

  return (
      <main className="main-wrapper">
        {
            !isLoading ?

                isAuthenticated ?
                <section key={isAuthenticated} className="main-content">
                    <h1>Sucesso!</h1>
                    <div className="image-container bigger">
                        <img src={success_message} alt="Sucesso" />
                    </div>
                    <p>Você já pode fechar essa tela.</p>
                </section>
                :
                <section key={isAuthenticated} className="main-content">
                    <h1>Ocorreu um Erro.</h1>
                    <div className="image-container">
                        <img src={error_message} alt="Erro" />
                    </div>
                    <p>Não foi possível realizar a integração. Por favor tente novamente.</p>
                </section>

            :
            <section key={isLoading} className="main-content">
                <h1 className="main-content-tile-flex">Carregando <LoadingDots size="1rem" color="#fff"/></h1>
                <div className="image-container smaller">
                    <img src={loading_image} alt="Erro" />
                </div>
                <p>Carregando os dados da requisição....</p>
            </section>

        }
        </main>
  );
}

export default LandingPage;
